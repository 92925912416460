import React from "react";
import { Routes, Route } from "react-router-dom";

//----components----
import Loader from "./components/loader/Loader";
// const Home = React.lazy(() => import("./components/home/Home"));
const ServiceDown = React.lazy(() => import("./components/service_down/index"));

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              {/* <Home /> */}
              <ServiceDown />
            </React.Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
